<template>
  <div>
    <div class="row">
      <div class="col-lg-5 col-md-5 col-sm-5">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="table"
            title="설비 목록"
            :columns="grid.columns"
            :gridHeight="grid.height"
            :data="grid.data"
            :filtering="false"
            :selection="popupParam.selectData.length > 0 ? 'multiple' : 'none'"
            :columnSetting="false"
            :usePaging="false"
            :isExcelDown="false"
            :checkClickFlag="false"
            :editable="editable"
            noDataLabel="정비계획할 설비를 추가하세요. 저장할 시 정비결과를 등록 할 수 있습니다."
            rowKey="minEquipmentMaintenanceId"
            checkDisableColumn="disable"
            @rowClick="rowClick"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn v-if="editable && !disabled && popupParam.selectData.length > 0" :showLoading="false" label="선택 점검완료" icon="save" color="blue"  @btnClicked="resultComplete" />
                <c-btn v-if="editable && !disabled && popupParam.selectData.length === 0" :showLoading="false" label="점검완료" icon="save" color="blue"  @btnClicked="resultComplete2" />
                <c-btn
                  v-if="editable && !disabled"
                  :url="updatesUrl"
                  :isSubmit="isSave"
                  :param="grid.data"
                  mappingType="PUT"
                  :label="popupParam.selectData.length > 0 ? '일괄 점검결과 저장' : '점검결과 저장'"
                  icon="save"
                  @beforeAction="saveInspection"
                  @btnCallback="saveCallback" 
                />
              </q-btn-group>
              <q-btn
                icon="help"
                color="deep-purple-6"
                text-color="white"
                class="custom-btn"
                align="center"
                round>
                <q-tooltip anchor="bottom left" self="top left">
                  <div class="tooltipCustomTop">
                    버튼설명
                  </div>
                  <div class="tooltipCustom">
                  ④ 선택 정비완료 : 체크박스를 선택하여 정비완료 하는 버튼입니다.<br>
                  ⑤ 일괄 정비결과 저장 : 설비목록과 설비별 정비결과를 일괄적으로 저장하는 버튼입니다.<br>
                  </div>
                </q-tooltip>
              </q-btn>
            </template>
            <template v-slot:customArea="{ props, col }">
              <template v-if="col.name === 'complete' && popupParam.selectData.length > 0">
                <q-btn
                  class="glossy"
                  round 
                  size="sm"
                  :editable="editable"
                  :disable="props.row.disable"
                  color="blue-6"
                  icon="save"
                  @click.stop="resultComplete3(props.row)" />
              </template>
            </template>
          </c-table>
        </div>
      </div>
      <div class="col-7">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <q-form ref="editForm">
            <c-card class="cardClassDetailInfo" title="설비 기본정보">
              <template slot="card-detail">
                <div v-if="editable && result.plantNm" class="col-3">
                  <c-label-text title="사업장" :value="result.plantNm"></c-label-text>
                </div>
                <div v-if="editable && result.equipmentTypeCd" class="col-3">
                  <c-label-text title="설비유형" :value="result.equipmentTypeNm"></c-label-text>
                </div>
                <div v-if="editable && result.equipmentCd" class="col-3">
                  <c-label-text title="설비코드" :value="result.equipmentCd"></c-label-text>
                </div>
                <div v-if="editable && result.equipmentName" class="col-3">
                  <c-label-text title="설비명" :value="result.equipmentName"></c-label-text>
                </div>
                <div v-if="editable && result.maintenanceDueDate" class="col-3">
                  <c-label-text title="정비예정일" :value="result.maintenanceDueDate"></c-label-text>
                </div>
                <div v-if="editable && result.maintenanceDueUserName" class="col-3">
                  <c-label-text title="정비예정자" :value="result.maintenanceDueUserName"></c-label-text>
                </div>
                <div v-if="editable && result.recentMaintainDate" class="col-3">
                  <c-label-text title="이전정비일" :value="result.recentMaintainDate"></c-label-text>
                </div>
                <div v-if="editable && result.pmCycleName" class="col-3">
                  <c-label-text title="예방정비주기" :value="result.pmCycleName"></c-label-text>
                </div>
                <div v-if="editable && result.month" class="col-3">
                  <c-label-text title="차기법정예정월" :value="result.month"></c-label-text>
                </div>
                <div v-if="editable && result.relatedLawsName" class="col-6">
                  <c-label-text title="관련법규" :value="result.relatedLawsName"></c-label-text>
                </div>
              </template>
            </c-card>
            <c-card class="cardClassDetailForm" title="설비 정비결과" bgClass="">
              <template slot="card-button">
                <q-btn-group outline >
                  <c-btn
                    v-if="saveDisable && !disabled"
                    :url="updateUrl"
                    :isSubmit="isSave2"
                    :param="result"
                    mappingType="PUT"
                    label="정비결과 저장"
                    icon="save"
                    @beforeAction="saveItemInspection"
                    @btnCallback="saveCallback" 
                  />
                </q-btn-group>
              </template>
              <template slot="card-detail">
                <div class="col-3">
                  <c-field
                    required
                    :disabled="result.disable"
                    label="정비부서 입회자"
                    name="memDeptEntrantUserId"
                    type="user" 
                    v-model="result.memDeptEntrantUserId">
                  ></c-field>
                </div>
                <div class="col-3">
                  <c-select
                    required
                    :disabled="result.disable"
                    label="정비유형"
                    codeGroupCd="MDM_MAINTENANCE_KIND_CD"
                    type="edit"
                    itemText="codeName"
                    itemValue="code"
                    v-model="result.equipmentCheckTypeCd"
                  ></c-select>
                </div>
                <div class="col-3">
                  <c-field
                    required
                    :disabled="result.disable"
                    label="실제정비자"
                    name="maintenanceUserId"
                    type="user" 
                    v-model="result.maintenanceUserId">
                  </c-field>
                </div>
                <div class="col-3">
                  <c-datepicker
                    required
                    :disabled="result.disable"
                    :editable="editable"
                    label="실제정비일"
                    name="maintenanceDate"
                    v-model="result.maintenanceDate">
                  </c-datepicker>
                </div>
                <div class="col-3">
                  <c-select
                    required
                    :disabled="result.disable"
                    :editable="editable"
                    codeGroupCd="MIM_CHECK_RESULT_CD2"
                    type="edit"
                    itemText="codeName"
                    itemValue="code"
                    name="inspResultAfterMemCd"
                    label="정비후 정비결과"
                    v-model="result.inspResultAfterMemCd"
                  ></c-select>
                </div>
                <div class="col-3">
                  <c-datepicker
                    :disabled="result.disable"
                    :editable="editable"
                    label="정비시간"
                    :range="true"
                    :minuteStep="10"
                    type="time"
                    name="maintenanceTimeList"
                    v-model="result.maintenanceTimeList">
                  </c-datepicker>
                </div>
                <div class="col-3">
                  <c-datepicker
                    :disabled="result.disable"
                    :editable="editable"
                    label="운전정지시간"
                    :range="true"
                    :minuteStep="10"
                    type="time"
                    name="stopDrivingTimeList"
                    v-model="result.stopDrivingTimeList">
                  </c-datepicker>
                </div>
                <div v-if="editable" class="col-3">
                  <c-text
                    :afterIcon="!disabled ? [
                      { name: 'search', click: true, callbackName: 'searchMocMaster', color: 'teal' },
                      { name: 'close', click: true, callbackName: 'removeMocMaster', color: 'red' }
                    ]: null "
                    :editable="editable"
                    label="MOC번호"
                    :disabled="true"
                    name="mocId"
                    @searchMocMaster="searchMocMaster"
                    @removeMocMaster="removeMocMaster"
                    v-model="result.mocId"
                  >
                  </c-text>
                </div>
                <div class="col-12">
                  <c-text
                    :editable="editable"
                    :disabled="result.disable"
                    label="정비자 자격사항"
                    name="maintenanceQualifications"
                    v-model="result.maintenanceQualifications">
                  </c-text>
                </div>
                <div class="col-12">
                  <c-textarea
                    :editable="editable"
                    :disabled="result.disable"
                    label="정비원인"
                    name="maintenanceCause"
                    v-model="result.maintenanceCause">
                  </c-textarea>
                </div>
                <div class="col-12">
                  <c-textarea
                    :editable="editable"
                    :disabled="result.disable"
                    label="정비내용"
                    name="maintenanceContent"
                    v-model="result.maintenanceContent">
                  </c-textarea>
                </div>
                <div class="col-12">
                  <c-textarea
                    :editable="editable"
                    :disabled="result.disable"
                    label="정비결과요약"
                    name="maintenanceResultSummary"
                    v-model="result.maintenanceResultSummary">
                  </c-textarea>
                </div>
                <div class="col-12">
                  <c-textarea
                    :editable="editable"
                    :disabled="result.disable"
                    label="관리자의 검토의견"
                    name="reviewOpinionOfMananger"
                    v-model="result.reviewOpinionOfMananger">
                  </c-textarea>
                </div>
              </template>
            </c-card>
          </q-form>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-maintenance-result-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        title: '',
        noPlan: 'N',
        selectData: [],
      }),
    },
  },
  data() {
    return {
      editable: true,
      result: {
        minEquipmentMaintenanceId: '',
        plantCd: '',
        equipmentCd: '',
        maintenanceStatusCd: null,
        equipmentTypeCd: '',
        equipmentCheckTypeCd: null,
        memDeptEntrantUserId: '',
        maintenanceDueDate: '',
        maintenanceDueUserId: '',
        mocId: '',
        maintenanceUserId: '',
        maintenanceQualifications: '',
        maintenanceResultSummary: '',
        inspResultAfterMemCd: null,
        noPlanResultEnrollFlag: 'N',
        maintenanceCause: '',
        maintenanceContent: '',
        maintenanceStartTime: '',
        maintenanceEndTime: '',
        stopDrivingStartTime: '',
        stopDrivingEndTime: '',
        reviewOpinionOfMananger: '',
        stopDrivingTimeList: [],
        maintenanceTimeList: [],
      },
      grid: {
        columns: [],
        data: [],
        height: '760px'
      },
      title: '',
      chkRsltYnItems: [],
      insertUrl: '',
      updateUrl: '',
      updatesUrl: '',
      getUrl: '',
      isSave: false,
      isSave2: false,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      
    };
  },
  computed: {
    disabled() {
      let count = 0;
      this.$_.forEach(this.grid.data, _item => {
        if(_item.disable) count++;
      })
      return count === this.grid.data.length;
    },
    saveDisable() {
      return this.popupParam.selectData.length > 0 && this.result && !this.result.disable && this.editable;
    }
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.getUrl = selectConfig.sop.min.maintenance.plan.get.url;
      this.updatesUrl = transactionConfig.sop.min.maintenance.result.updates.url;
      this.updateUrl = transactionConfig.sop.min.maintenance.result.update.url;
      this.completeUrl = transactionConfig.sop.min.maintenance.result.completes.url;
      // code setting
      this.chkRsltYnItems = [
        { code: 'Y', codeName: '완료' },
        { code: 'N', codeName: '미완료' },
      ]
      this.setHeader();
      // list setting
      this.title = this.popupParam.title;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.selectData.length > 0) {
        this.$_.forEach(this.popupParam.selectData, _item => {
          this.$http.url = this.$format(this.getUrl, _item.minEquipmentMaintenanceId);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            this.grid.data.push(_result.data)
            this.$_.forEach(this.grid.data, _item => {
              if (_item.stopDrivingStartTime && _item.stopDrivingEndTime) {
                _item.stopDrivingTimeList = [_item.stopDrivingStartTime, _item.stopDrivingEndTime];
              }
              if (_item.maintenanceStartTime && _item.maintenanceEndTime) {
                _item.maintenanceTimeList = [_item.maintenanceStartTime, _item.maintenanceEndTime];
              }
            })
          },);
        })
      } else {
        if (this.popupParam.minEquipmentMaintenanceId) {
          this.$http.url = this.$format(this.getUrl, this.popupParam.minEquipmentMaintenanceId);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            this.grid.data.push(_result.data);
             this.$_.forEach(this.grid.data, _item => {
              if (_item.stopDrivingStartTime && _item.stopDrivingEndTime) {
                _item.stopDrivingTimeList = [_item.stopDrivingStartTime, _item.stopDrivingEndTime];
              }
              if (_item.maintenanceStartTime && _item.maintenanceEndTime) {
                _item.maintenanceTimeList = [_item.maintenanceStartTime, _item.maintenanceEndTime];
              }
            })
            this.rowClick(this.grid.data[0], 'temp')
          },);
        }
      }
    },
    setHeader() {
      if (this.popupParam.selectData.length > 0) {
        this.grid.columns = [
          {
            name: 'equipmentTypeNm',
            field: 'equipmentTypeNm',
            label: '설비유형',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'equipmentCd',
            field: 'equipmentCd',
            label: '설비코드',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            style: 'width:120px',
            align: 'center',
            sortable: false
          },
          {
            name: 'maintenanceDueUserName',
            field: 'maintenanceDueUserName',
            label: '점검예정자',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'maintenanceDueDate',
            field: 'maintenanceDueDate',
            label: '점검예정일',
            style: 'width:100px',
            align: 'center',
            sortable: false,
          },
          // {
          //     name: 'click',
          //     field: 'click',
          //     label: '항목클릭',
          //     align: 'center',
          //     style: 'width:50px',
          //     sortable: false,
          //     type: "custom",
          //   },
          {
            name: 'complete',
            field: 'complete',
            label: '완료',
            align: 'center',
            style: 'width:50px',
            sortable: false,
            type: "custom",
          },
        ]
      } else {
        this.grid.columns = [
          {
            name: 'equipmentTypeNm',
            field: 'equipmentTypeNm',
            label: '설비유형',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'equipmentCd',
            field: 'equipmentCd',
            label: '설비코드',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            style: 'width:120px',
            align: 'center',
            sortable: false
          },
          {
            name: 'maintenanceDueUserName',
            field: 'maintenanceDueUserName',
            label: '점검예정자',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'maintenanceDueDate',
            field: 'maintenanceDueDate',
            label: '점검예정일',
            style: 'width:100px',
            align: 'center',
            sortable: false,
          },
        ]
      }
    },
    saveInspection() {
      let flag = false;
      this.$_.forEach(this.grid.data, _item => {
        if (!_item.memDeptEntrantUserId || !_item.equipmentCheckTypeCd || !_item.maintenanceUserId || !_item.inspResultAfterMemCd || !_item.maintenanceDate) {
            window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: '필수 입력값을 입력해 주세요. [정비부서 입회자, 정비유형, 실제정비자, 실제정비일, 정비후 정비결과]', // 하나 이상 선택하세요.
            type: 'warning', // success / info / warning / error
          });
          flag = true;
        }
      })    
      if (!flag) { 
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid.data, _item => {
              if (_item.maintenanceTimeList && _item.maintenanceTimeList.length > 0) {
                _item.maintenanceStartTime = _item.maintenanceTimeList[0];
                _item.maintenanceEndTime = _item.maintenanceTimeList[1];
              } 
              if (_item.stopDrivingTimeList&& _item.stopDrivingTimeList.length > 0) {
                _item.stopDrivingStartTime = _item.stopDrivingTimeList[0];
                _item.stopDrivingEndTime = _item.stopDrivingTimeList[1];
              } 
            })
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } 
    },
    saveItemInspection() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave2 = !this.isSave2;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
    resultComplete() {
      let selectData = this.$refs['table'].selected;
      if (selectData && selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // '안내',
          message: '하나 이상 선택하세요.', 
          type: 'warning',
        });
        return;
      }
      let flag = false;
      this.$_.forEach(selectData, _item => {
        if (!_item.memDeptEntrantUserId || !_item.equipmentCheckTypeCd || !_item.maintenanceUserId || !_item.inspResultAfterMemCd || !_item.maintenanceDate) {
            window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: '필수 입력값을 입력해 주세요. [정비부서 입회자, 정비유형, 실제정비자, 실제정비일, 정비후 정비결과]', // 하나 이상 선택하세요.
            type: 'warning', // success / info / warning / error
          });
          flag = true;
        }
      }) 
      if (!flag) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '점검완료 하시겠습니까?(저장과 함께 완료처리가 됩니다.)',
          // TODO : 점검완료 하시겠습니까?
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(selectData, _item => {
              _item.maintenanceCompleteFlag = 'Y';
            })
            this.$http.url = this.completeUrl;
            this.$http.type = 'PUT';
            this.$http.param = selectData;
            this.$http.request(() => {
              this.$_.forEach(selectData, _item => {
                _item.maintenanceStatusCd = 'MCSC000020';
                _item.disable = true;
              })
              this.$refs['table'].selected = [];
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
      });
      }
    },
    resultComplete2() {
      let flag = false;
      this.$_.forEach(this.grid.data, _item => {
        if (!_item.memDeptEntrantUserId || !_item.equipmentCheckTypeCd || !_item.maintenanceUserId || !_item.inspResultAfterMemCd || !_item.maintenanceDate) {
            window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: '필수 입력값을 입력해 주세요. [정비부서 입회자, 정비유형, 실제정비자, 실제정비일,  정비후 정비결과]', // 하나 이상 선택하세요.
            type: 'warning', // success / info / warning / error
          });
          flag = true;
        }
      })  
      if (!flag) {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
          message: '점검완료 하시겠습니까?(저장과 함게 완료처리가 됩니다.)',
          // TODO : 점검완료 하시겠습니까?
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid.data, _item => {
              _item.maintenanceCompleteFlag = 'Y';
            })
            this.$http.url = this.completeUrl;
            this.$http.type = 'PUT';
            this.$http.param = this.grid.data;
            this.$http.request(() => {
              this.getDetail();
              this.emit('SAVE');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
      })
      }
    },
    // 단일 점검완료
    resultComplete3(data) {
      if (!data.memDeptEntrantUserId || !data.equipmentCheckTypeCd || !data.maintenanceUserId || !data.inspResultAfterMemCd || !data.maintenanceDate) {
          window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [정비부서 입회자, 정비유형, 실제정비자, 실제정비일, 정비후 정비결과]', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '점검완료 하시겠습니까?(저장과 함게 완료처리가 됩니다.)',
        // TODO : 점검완료 하시겠습니까?
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          data.maintenanceCompleteFlag = 'Y';
          this.$http.url = transactionConfig.sop.min.maintenance.result.complete.url;
          this.$http.type = 'PUT';
          this.$http.param = data;
          this.$http.request(() => {
            data.disable = true;
            data.maintenanceStatusCd = 'MCSC000020';
            this.$refs['table'].selected = [];
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
      },
      });
    },
    rowClick(row, col) {
      if (col.name === 'mocNo') {
        this.linkClick(row);
      } else {
        this.result = row;
      }
    },
    btnRecord() {
      this.popupOptions.target = () =>
      import(`${"@/pages/mdm/fim/equipmentPreview.vue"}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '40%';
      this.popupOptions.param = this.result;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    searchMocMaster() {
      this.popupOptions.title = '설비관련 MOC 검색'; // 설비관련 MOC 검색
      this.popupOptions.param = {
        type: 'single'
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/moc/mocPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeMocPopup;
    },
    closeMocPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (!data) return;
      
      this.result.mocId = data.chngNum
    },
    removeMocMaster() {
      this.result.mocId = '';
    },
    emit(result) {
      if (result === 'COMPLETE') {
        this.$emit('closePopup', result);
      } else if (result === 'SAVE') {
        this.$emit('closePopup', result);
      } 
    },
  }
};
</script>
